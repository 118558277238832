import React from 'react';
import PageWrapper from './pageWrapper';
import Footer from '../footer';
import styled from '@emotion/styled';


const Layout = React.memo(({ children }) => {
  return (
    <>
      <PageWrapper>
        {children}
      </PageWrapper>
      <Footer />
    </>
  );
})

export default Layout;