import React from 'react';
import styled from '@emotion/styled';
import {Link} from 'gatsby'
import InstallPWA from './elements/installPWA'

const StyledFooter = styled.footer`
  ${tw`p-8 bg-primary-light flex flex-col justify-center bg-green items-center shadow font-title text-xl`};
  a {
    ${tw`p-1 inline-block border-0 border-b border-dotted text-xl sm:text-base font-semibold`}
  }
  p{
    ${tw`pt-8 pb-2`}
  }
  .link-button{
    ${tw`text-xl border-2 border-white bg-primary-lighter px-2 border-solid`}
    border-radius: 7px;
  }
  
`;

const Separator = styled.div`
  ${tw`p-2`}
`

const Credit = styled.p`
  ${tw`text-xs`}
  a{
    ${tw`text-xs`}
  }
  
`

export default React.memo(function footer() {
  return (
    <StyledFooter>
      <InstallPWA />
      <Separator />
      <Link to="/pourquoi-consodurable">
        Pourquoi consodurable ?
      </Link>
      <Separator />
      <Link to="/inscription">
        Apparaître sur le site
      </Link>
      <Separator />
      <p>Une initiative de <a href="mailto:remy@consodurable.ch">Rémy Fontaine</a></p>
      <Credit>développé par <a href="http://chapatte.co"><cite>Corentin Chapatte</cite></a></Credit>
    </StyledFooter>
  );
}, () => { return true });
