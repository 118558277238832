module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/data/src/components/elements/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-46181834-8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"consodurable.ch","short_name":"consodurable.ch","start_url":"/","background_color":"#b4da11","theme_color":"#d5f262","display":"standalone","icon":"src/images/favicon.jpg","icons":[{"src":"/favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
